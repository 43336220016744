@import url(https://fonts.googleapis.com/css?family=Anton&display=swap&subset=latin-ext);
@font-face {
  font-family: 'ElektraLight';
  src: url(/static/media/ElektraLightProRegular.3375c518.eot) format("eot"), url(/static/media/ElektraLightProRegular.68435dfb.woff2) format("woff2"), url(/static/media/ElektraLightProRegular.79181d27.woff) format("woff"), url(/static/media/ElektraLightProRegular.d961f352.ttf) format("truetype"), url(/static/media/ElektraLightProRegular.98f94cb5.svg) format("svg"); }

@font-face {
  font-family: 'ElektraMedium';
  src: url(/static/media/ElektraMediumProRegular.e9df77ea.eot) format("eot"), url(/static/media/ElektraMediumProRegular.b58fecfb.woff2) format("woff2"), url(/static/media/ElektraMediumProRegular.5fe7bdf7.woff) format("woff"), url(/static/media/ElektraMediumProRegular.3a3e987b.ttf) format("truetype"), url(/static/media/ElektraMediumProRegular.a44eb87e.svg) format("svg"); }

@font-face {
  font-family: 'ElektraBold';
  src: url(/static/media/ElektraMediumProBold.407f88f0.eot) format("eot"), url(/static/media/ElektraMediumProBold.933564fa.woff2) format("woff2"), url(/static/media/ElektraMediumProBold.2bd446be.woff) format("woff"), url(/static/media/ElektraMediumProBold.79b7525a.ttf) format("truetype"), url(/static/media/ElektraMediumProBold.9ef772ac.svg) format("svg"); }

* {
  box-sizing: border-box; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  font-family: ElektraLight, 'sans-serif' !important; }

h1, h2, h3, h4, h5 {
  font-family: ElektraBold, 'sans-serif' !important; }

.content-wrapper {
  background: linear-gradient(rgba(255, 255, 255, 0.2) 100%, rgba(255, 255, 255, 0.2) 100%), url(/static/media/background.8dd231a5.jpg) no-repeat fixed;
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  z-index: -1; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

.page-container {
  background: rgba(255, 255, 255, 0.8);
  height: 100vh;
  z-index: 99;
  box-shadow: 0px 0px 180px 186px rgba(255, 255, 255, 0.8); }

.product-listing {
  display: flex;
  flex: 1 1;
  flex-wrap: wrap;
  flex-direction: row;
  margin-right: 5px; }
  .product-listing .category {
    margin-left: 15px;
    padding: 15px 10px; }
    .product-listing .category:first-child {
      padding: 0 10px 15px; }
    .product-listing .category:not(:last-child) {
      border-bottom: 1px solid #ccc; }
    .product-listing .category .productName {
      margin: 0;
      align-items: center;
      height: 100%;
      display: flex; }
      .product-listing .category .productName.withExtra {
        height: auto; }
    .product-listing .category .extraLabel {
      margin-top: 5px;
      cursor: pointer;
      padding-left: 15px;
      position: relative;
      color: #9E2023;
      display: block; }
      .product-listing .category .extraLabel span {
        width: 18px;
        height: 18px;
        border: 2px solid #9E2023;
        position: absolute;
        left: 0;
        top: 2px; }
      .product-listing .category .extraLabel input[type="checkbox"] {
        visibility: hidden; }
      .product-listing .category .extraLabel input:checked + span:after {
        content: '';
        margin-left: 3px;
        width: 6px;
        height: 12px;
        border: solid #9E2023;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
        display: block; }
      @media screen and (max-width: 991px) {
        .product-listing .category .extraLabel {
          margin-top: -15px;
          padding-left: 25px; }
          .product-listing .category .extraLabel span {
            top: 25px; } }
    .product-listing .category .productPrice {
      background: #9E2023;
      color: #FFFFFF;
      width: 60px;
      height: 60px;
      transform: rotate(-20deg);
      padding-top: 18px;
      padding-left: 5px;
      border-radius: 100%; }
      .product-listing .category .productPrice.smaller {
        font-size: 13px;
        padding-top: 20px; }
    .product-listing .category .product-list-item {
      margin-bottom: 15px; }
      .product-listing .category .product-list-item .hiddenAlergens {
        position: absolute;
        display: none;
        width: 300px;
        left: 0;
        color: #FFFFFF;
        background: #9e2023;
        padding: 15px;
        z-index: 2;
        margin-top: 7px; }
        .product-listing .category .product-list-item .hiddenAlergens:hover {
          display: block; }
        .product-listing .category .product-list-item .hiddenAlergens:before {
          content: "\A";
          border-style: solid;
          border-width: 10px 15px 10px 0;
          border-color: transparent #9e2023 transparent transparent;
          position: absolute;
          top: -15px;
          transform: rotate(90deg); }
      .product-listing .category .product-list-item button.alergens {
        font-style: italic;
        cursor: pointer;
        font-size: 12px;
        position: relative;
        margin-bottom: 0;
        outline: none;
        border: none;
        padding: 0; }
        .product-listing .category .product-list-item button.alergens:hover + .hiddenAlergens {
          display: block; }
    @media screen and (max-width: 991px) {
      .product-listing .category {
        padding-left: 0 !important;
        padding-right: 0 !important; } }

button {
  background: transparent;
  border: none;
  outline: none; }

.userAccount {
  float: right;
  right: 30px;
  position: absolute;
  outline: none !important;
  transition: all .25s linear; }
  .userAccount svg {
    margin-right: 5px;
    margin-top: -3px;
    transition: all .25s linear; }
  .userAccount:hover {
    color: #9E2023; }
    .userAccount:hover svg {
      fill: #9E2023; }

a.navigationBack {
  color: #9E2023;
  text-decoration: underline;
  margin-left: 45px;
  position: relative;
  display: block; }
  a.navigationBack:before {
    content: '<';
    position: absolute;
    margin-left: -15px; }
  @media screen and (max-width: 991px) {
    a.navigationBack {
      margin: 15px auto 0 15px; } }

@media screen and (max-width: 991px) {
  input, select {
    width: 100% !important; } }

/* width */
::-webkit-scrollbar {
  width: 10px; }

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #9E2023; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e13940; }

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

