@font-face {
  font-family: 'ElektraLight';
  src: url('./assets/fonts/ElektraLightProRegular.eot') format('eot'),
  url('./assets/fonts/ElektraLightProRegular.woff2') format('woff2'),
  url('./assets/fonts/ElektraLightProRegular.woff') format('woff'),
  url('./assets/fonts/ElektraLightProRegular.ttf') format('truetype'),
  url('./assets/fonts/ElektraLightProRegular.svg') format('svg');
}

@font-face {
  font-family: 'ElektraMedium';
  src: url('./assets/fonts/ElektraMediumProRegular.eot') format('eot'),
  url('./assets/fonts/ElektraMediumProRegular.woff2') format('woff2'),
  url('./assets/fonts/ElektraMediumProRegular.woff') format('woff'),
  url('./assets/fonts/ElektraMediumProRegular.ttf') format('truetype'),
  url('./assets/fonts/ElektraMediumProRegular.svg') format('svg');
}

@font-face {
  font-family: 'ElektraBold';
  src: url('./assets/fonts/ElektraMediumProBold.eot') format('eot'),
  url('./assets/fonts/ElektraMediumProBold.woff2') format('woff2'),
  url('./assets/fonts/ElektraMediumProBold.woff') format('woff'),
  url('./assets/fonts/ElektraMediumProBold.ttf') format('truetype'),
  url('./assets/fonts/ElektraMediumProBold.svg') format('svg');
}

@import url('https://fonts.googleapis.com/css?family=Anton&display=swap&subset=latin-ext');

@import './helpers/variables';

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  font-family: ElektraLight, 'sans-serif' !important;
}

h1, h2, h3, h4, h5 {
  font-family: ElektraBold, 'sans-serif' !important;
}

.content-wrapper {
  background: linear-gradient(rgba(255, 255, 255, .2) 100%, rgba(255, 255, 255, .2) 100%), url('assets/img/background.jpg') no-repeat fixed;
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  z-index: -1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.page-container {
  background: rgba($white, .8); //#9E2023
  height: 100vh;
  z-index: 99;
  -webkit-box-shadow: 0px 0px 180px 186px rgba(255, 255, 255, .8);
  -moz-box-shadow: 0px 0px 180px 186px rgba(255, 255, 255, .8);
  box-shadow: 0px 0px 180px 186px rgba(255, 255, 255, .8);
}

.product-listing {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  flex-direction: row;
  margin-right: 5px;

  .category {
    margin-left: 15px;
    padding: 15px 10px;
    &:first-child {
      padding: 0 10px 15px;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #ccc;
    }

    .productName {
      margin: 0;
      align-items: center;
      height: 100%;
      display: flex;

      &.withExtra {
        height: auto;
      }
    }

    .extraLabel {
      margin-top: 5px;
      cursor: pointer;
      padding-left: 15px;
      position: relative;
      color: #9E2023;
      display: block;

      span {
        width: 18px;
        height: 18px;
        border: 2px solid #9E2023;
        position: absolute;
        left: 0;
        top: 2px;
      }

      input[type="checkbox"] {
        visibility: hidden;
      }

      input:checked + span {
        &:after {
          content: '';
          margin-left: 3px;
          width: 6px;
          height: 12px;
          border: solid #9E2023;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
          display: block;
        }

      }
      @media screen and (max-width: 991px) {
        margin-top: -15px;
        padding-left: 25px;
        span {
          top: 25px;
        }
      }
    }

    .productPrice {
      background: #9E2023;
      color: #FFFFFF;
      width: 60px;
      height: 60px;
      transform: rotate(-20deg);
      padding-top: 18px;
      padding-left: 5px;
      border-radius: 100%;

      &.smaller {
        font-size: 13px;
        padding-top: 20px;
      }
    }

    .product-list-item {
      margin-bottom: 15px;
      .hiddenAlergens {
        position: absolute;
        display: none;
        width: 300px;
        left: 0;
        color: #FFFFFF;
        background: rgba(#9E2023, 1);
        padding: 15px;
        z-index: 2;
        margin-top: 7px;
        &:hover {
          display: block;
        }
        &:before {
          content:"\A";
          border-style: solid;
          border-width: 10px 15px 10px 0;
          border-color: transparent rgba(#9E2023, 1) transparent transparent;
          position: absolute;
          top: -15px;
          -webkit-transform:rotate(90deg);
          -moz-transform: rotate(90deg);
          -ms-transform: rotate(90deg);
          -o-transform: rotate(90deg);
          transform: rotate(90deg);
        }
      }
      button.alergens {
        font-style: italic;
        cursor: pointer;
        font-size: 12px;
        position: relative;
        margin-bottom: 0;
        outline: none;
        border: none;
        padding: 0;
        &:hover {
          + .hiddenAlergens {
            display: block;
          }
        }
      }
    }
    @media screen and (max-width: 991px) {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}


button {
  background: transparent;
  border: none;
  outline: none;
}

.userAccount {
  float: right;
  right: 30px;
  position: absolute;
  outline: none !important;
  -moz-transition: all .25s linear;
  -o-transition: all .25s linear; -ms-transition: all 2.25s linear;
  -webkit-transition: all .25s linear;
  transition: all .25s linear;
  svg {
    margin-right: 5px;
    margin-top: -3px;
    -moz-transition: all .25s linear;
    -o-transition: all .25s linear; -ms-transition: all 2.25s linear;
    -webkit-transition: all .25s linear;
    transition: all .25s linear;
  }
  &:hover {
    color: #9E2023;
    svg {
      fill: #9E2023;
    }
  }
}

a.navigationBack {
  color: #9E2023;
  text-decoration: underline;
  margin-left: 45px;
  position: relative;
  display: block;
  &:before {
    content: '<';
    position: absolute;
    margin-left: -15px;
  }
  @media screen and (max-width: 991px) {
    margin: 15px auto 0 15px;
  }
}

@media screen and (max-width: 991px) {
 input, select {
   width: 100% !important;
 }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #9E2023;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e13940;
}
